import IconAssignmentOutlined from '@material-ui/icons/AssignmentOutlined'
import IconAssignmentTurnedInOutlined from '@material-ui/icons/AssignmentTurnedInOutlined'
import { FunctionComponent, useState } from 'react'
import { copyToClipboard, RESET_MILLISECONDS, WORDS } from '../../utils/copyToClipboard'

export interface CopyToClipboardButtonProps {
	text: string
}

export const CopyToClipboardButton: FunctionComponent<CopyToClipboardButtonProps> = ({ text }) => {
	const [didClick, setDidClick] = useState(false)
	return (
		<button
			className="btn-copy-to-clipboard"
			title={!didClick ? WORDS.COPY_TO_CLIPBOARD : WORDS.COPIED}
			aria-label={!didClick ? WORDS.COPY_TO_CLIPBOARD : WORDS.COPIED}
			onClick={() => {
				setDidClick(true)
				copyToClipboard(text)
				window.setTimeout(() => {
					setDidClick(false)
				}, RESET_MILLISECONDS)
			}}>
			{!didClick ? <IconAssignmentOutlined /> : <IconAssignmentTurnedInOutlined />}
		</button>
	)
}
