import { Dictionary } from 'lodash'
import { NUMBER_FORMAT_TYPE } from '../types/NumberFormatType'
import { CalculatedValueVariableParameters, RandomNumberVariableParameters } from '../types/Variable'

export const GREEK_LETTERS: Dictionary<{ command: string; text: string }> = {
	Α: { command: '\\char"000391', text: 'Alpha' },
	α: { command: '\\alpha', text: 'alpha' },
	Β: { command: '\\char"000392', text: 'Beta' },
	β: { command: '\\beta', text: 'beta' },
	Γ: { command: '\\Gamma', text: 'Gamma' },
	γ: { command: '\\gamma', text: 'gamma' },
	Δ: { command: '\\Delta', text: 'Delta' },
	δ: { command: '\\delta', text: 'delta' },
	Ɛ: { command: '\\char"000190', text: 'Epsilon' },
	ϵ: { command: '\\epsilon', text: 'epsilon' },
	ε: { command: '\\varepsilon', text: 'epsilon variant' },
	Ζ: { command: '\\char"000396', text: 'Zeta' },
	ζ: { command: '\\zeta', text: 'zeta' },
	Η: { command: '\\char"000397', text: 'Eta' },
	η: { command: '\\eta', text: 'eta' },
	Θ: { command: '\\Theta', text: 'Theta' },
	θ: { command: '\\theta', text: 'theta' },
	ϑ: { command: '\\vartheta', text: 'theta variant' },
	Ι: { command: '\\char"000399', text: 'Iota' },
	ι: { command: '\\iota', text: 'iota' },
	Κ: { command: '\\char"00039A', text: 'Kappa' },
	κ: { command: '\\kappa', text: 'kappa' },
	ϰ: { command: '\\varkappa', text: 'kappa variant' },
	Λ: { command: '\\Lambda', text: 'Lambda' },
	λ: { command: '\\lambda', text: 'lambda' },
	Μ: { command: '\\char"00039C', text: 'Mu' },
	μ: { command: '\\mu', text: 'mu' },
	Ν: { command: '\\char"00039D', text: 'Nu' },
	ν: { command: '\\nu', text: 'nu' },
	Ξ: { command: '\\Xi', text: 'Xi' },
	ξ: { command: '\\xi', text: 'xi' },
	Ο: { command: '\\char"00039F', text: 'Omicron' },
	ο: { command: '\\omicron', text: 'omicron' },
	Π: { command: '\\Pi', text: 'Pi' },
	π: { command: '\\pi', text: 'pi' },
	ϖ: { command: '\\varpi', text: 'pi variant' },
	Ρ: { command: '\\char"0003A1', text: 'Rho' },
	ρ: { command: '\\rho', text: 'rho' },
	ϱ: { command: '\\varrho', text: 'rho variant' },
	Σ: { command: '\\Sigma', text: 'Sigma' },
	σ: { command: '\\sigma', text: 'sigma' },
	ς: { command: '\\varsigma', text: 'sigma variant' },
	Τ: { command: '\\char"0003A4', text: 'Tau' },
	τ: { command: '\\tau', text: 'tau' },
	Υ: { command: '\\Upsilon', text: 'Upsilon' },
	υ: { command: '\\upsilon', text: 'upsilon' },
	Φ: { command: '\\Phi', text: 'Phi' },
	ϕ: { command: '\\phi', text: 'phi' },
	φ: { command: '\\varphi', text: 'phi variant' },
	Χ: { command: '\\char"0003A7', text: 'Chi' },
	χ: { command: '\\chi', text: 'chi' },
	Ψ: { command: '\\Psi', text: 'Psi' },
	ψ: { command: '\\psi', text: 'psi' },
	Ω: { command: '\\Omega', text: 'Omega' },
	ω: { command: '\\omega', text: 'omega' }
}

export const greekLetterForCommand = (command: string) => {
	const matches = Object.entries(GREEK_LETTERS).find(([key, value]) => {
		return value.command === command
	})
	if (matches) {
		return matches[0][0]
	}
	return undefined
}

const greekLetterCommands = `\\${Object.values(GREEK_LETTERS)
	.map(v => `${v.command} ?`)
	.join('|\\')}`

const variableNameRegExPattern = `((?:[a-zA-Z0-9]|${greekLetterCommands})+(?:_(?:{(?:[a-zA-Z0-9,]|${greekLetterCommands})+}|(?:[a-zA-Z0-9]|${greekLetterCommands})))?)`

/**
 * Regex for matching a variable name with an unwrapped single char subscript
 */
export const variableNameUnwrappedSingleSubRegEx = new RegExp(
	`^((?:[a-zA-Z0-9]|${greekLetterCommands})+(_[a-zA-Z0-9,]))$`
)

/**
 * Regex for matching a variable name with a wrapped single char subscript
 */
export const variableNameWrappedSingleSubRegEx = new RegExp(
	`^((?:[a-zA-Z0-9]|${greekLetterCommands})+(_{([a-zA-Z0-9,])}))$`
)

/**
 * Regex for matching a variable name
 */
export const variableNameRegEx = new RegExp(`^${variableNameRegExPattern}$`)

/**
 * Regex for finding typed variables in Quill
 */
export const variableBracketRegEx = new RegExp(`{{${variableNameRegExPattern}}}`, 'gm')

/**
 * Regex for finding adjacent \variable commands in LaTeX
 *
 * Match is the first of the two variable commands
 */
export const adjacentLatexVariableRegEx = new RegExp(
	`(\\\\variable{${variableNameRegExPattern}})(?=\\\\variable{${variableNameRegExPattern}})`,
	'gm'
)

/**
 * Regex for finding a \variable command before a digit in LaTeX
 *
 * Match is only the variable command
 *
 */
export const latexVariableBeforeDigitRegEx = new RegExp(`(\\\\variable{${variableNameRegExPattern}})(?=\\d)`, 'gm')

/**
 * Regex for finding a \variable command after a digit in LaTeX.
 *
 * Ensures that the digit is not a single char subscript or superscript.
 *
 * Match will have three groups:
 * 1. The char preceding the digit, if any
 * 2. The digit
 * 3. The variable command
 *
 * The char and digit should be preserved during replacement.
 *
 */
export const latexVariableAfterDigitRegEx = new RegExp(
	`(^|[^_^])(\\d)(\\\\variable{${variableNameRegExPattern}})`,
	'gm'
)

/**
 * Regex for finding \variable commands in LaTeX
 */
export const latexVariableRegEx = new RegExp(`\\\\variable{${variableNameRegExPattern}}`, 'gm')

/**
 * Regex for finding typed variables in MathLive
 */
export const latexBracketVariableRegEx = new RegExp(
	`\\\\left\\\\lbrace \\\\left\\\\lbrace ${variableNameRegExPattern}\\\\right\\\\rbrace \\\\right\\\\rbrace`,
	'gm'
)

/**
	Number Format Pattern covers: Number and Percentage
	- start of string
	- optional '-'
	- optional
		- zero or more digits
		- period
	- one or more digits
	- optional '\%'
	- end of string
*/
export const latexNumberRegEx = new RegExp(/^(-?(?:(?:\d*\.)?\d+(?:\\%)?))$/, 'gm')

/**
	Number With Separators covers: Numbers with Separators and US Dollar
	- start of string
	- no leading zeroes, with optional decimal place and zeroes
	- optional '-'
	- optional '\$'
	- one to three digits
	- optional
		- zero or more
			- comma followed by three digits
	- optional
		- one period
		- one or more digits
	-end of string
	*/
export const latexNumberWithSeparatorsRegEx = new RegExp(/^(?!0+(?:\.0+)?)-?(?:\\\$)?\d{1,3}(,\d{3})*(\.\d+)?$/, 'gm')

/**
	Regex for Sci Notation formatted values
	- start of string
	- optional '-'
	- optional
		- zero or more digits
		- period
	- one or more digits
	- '\times 10^' exactly
	- non-capturing group
		- either a single digit
		- or brackets '{}' wrapping optional '-' and one or more digits
	- end of string
*/
export const latexSciNotationRegEx = new RegExp(/^-?(?:\d*\.)?\d+\\times 10\^(?:\d|(?:\{-?\d+\}))$/, 'gm')

/**
 * Regex for E Notation formatted values
 */
export const latexENotationRegEx = new RegExp(/^-?(?:\d*\.)?\d+E-?\d+$/, 'gm')

/**
 * Regex for Fraction formatted values
 */
export const latexFractionRegEx = new RegExp(/^-?\\frac{\d+}{\d+}$/, 'gm')

export const defaultCalculatedVariableParameters: CalculatedValueVariableParameters = {
	formula: '',
	numberFormatType: NUMBER_FORMAT_TYPE.NUMBER,
	rounding: 2
}

export const defaultRandomVariableParameters: Partial<RandomNumberVariableParameters> = {
	step: '1',
	blacklist: '[]',
	numberFormatType: NUMBER_FORMAT_TYPE.NUMBER
}
