import { FunctionComponent } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getServerNowUtc } from 'studiokit-scaffolding-js/lib/utils/date'
import { isDemoShard } from 'studiokit-scaffolding-js/lib/utils/shard'
import CONFIG from '../../configuration'
import purdueDefaultLogo from '../Shared/purdue.svg'
import purdueWhiteLogo from './purdue-white.svg'

import './index.css'

interface FooterProps {
	useDarkBackground: boolean
}

const Footer: FunctionComponent<FooterProps> = ({ useDarkBackground }) => {
	// Use a white Purdue logo when using a dark background and the gray one on other pages
	const purdueLogo = useDarkBackground ? purdueWhiteLogo : purdueDefaultLogo

	return (
		<footer className={`footer ${useDarkBackground ? 'dark' : ''}`}>
			<Container>
				<Row>
					<Col xs={12} md={5} className="footer-top">
						<a id="purdue-logo" href="http://www.purdue.edu" target="_blank" rel="noopener noreferrer">
							<Image src={purdueLogo} alt="Purdue University" />
						</a>
					</Col>
					<Col xs={12} md={7} className="footer-bottom">
						<p>
							Variate v{CONFIG.VERSION} - {CONFIG.VERSION_NAME}, brought to you by{' '}
							<a
								className="link"
								href="http://www.purdue.edu/purdue/disclaimer.html"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Purdue University Disclaimer">
								Purdue&nbsp;University
							</a>
							{'.'}
							<br /> {String.fromCharCode(169)} {getServerNowUtc().getFullYear()} Purdue University. An
							equal access, equal opportunity university.
							<br />
							Need help? Have trouble accessing this page because of a disability?{' '}
							{isDemoShard() && (
								<span>
									<br />
									Want to use Variate at your institution?{' '}
								</span>
							)}
							<a className="link" href="mailto:tlt@purdue.edu">
								Contact&nbsp;us.
							</a>
							<br />
							Read the <Link to={'/privacy-policy'}>privacy policy</Link>.
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	)
}

export default Footer
