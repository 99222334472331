import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { FunctionComponent } from 'react'

export const IconAIProblemCreator: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			d="m 21.57 14.1 h -7.7 c -0.6 0 -1.1 0.5 -1.1 1.1 v 7.7 c 0 0.6 0.5 1.1 1.1 1.1 h 7.7 c 0.6 0 1.1 -0.5 1.1
		 -1.1 v -7.7 c 0 -0.6 -0.5 -1.1 -1.1 -1.1 Z m -7 2.6 h 2.7 v 0.8 h -2.7 v -0.8 Z m 2.9 4.5 h -1.1 v 1.1 h -0.8 v
		  -1.1 h -1.1 v -0.8 h 1.1 v -1.1 h 0.8 v 1.1 h 1.1 v 0.8 Z m 3.6 0.7 h -2.7 v -0.8 h 2.7 v 0.8 Z m 0 -1.3 h -2.7 
		  v -0.8 h 2.7 v 0.8 Z m 0 -2.7 l -0.6 0.6 l -0.8 -0.8 l -0.8 0.8 l -0.6 -0.6 l 0.8 -0.8 l -0.8 -0.8 l 0.6 -0.6 
		  l 0.8 0.8 l 0.8 -0.8 l 0.6 0.6 l -0.8 0.8 l 0.8 0.8 Z m -12.1 -4.03 h 2.94 c -0.38 0.39 -0.62 0.9 -0.62 1.47 v
		   6.71 h -5.3 v -5.35 c 0 -1.56 1.34 -2.83 2.99 -2.83 Z m 9.85 -2.68 c 0.08 -0.81 0.12 -1.61 0.15 -2.4 c 0.24 
		   -0.19 0.39 -0.47 0.39 -0.78 l -0.02 -1.48 c 0 -0.31 -0.17 -0.59 -0.42 -0.77 c -0.03 -0.42 -0.05 -0.83 -0.1 
		   -1.26 c -0.02 -0.23 -0.21 -0.42 -0.44 -0.44 c -1.37 -0.16 -2.72 -0.26 -4.07 -0.32 c -0.05 -0.64 -0.58 -1.16 
		   -1.24 -1.16 h -0.56 l 0.03 -1.15 l -0.58 -0.4 l 0.2 -0.3 s 0.1 0.03 0.15 0.03 c 0.21 0 0.38 -0.17 0.38 -0.38 
		   s -0.17 -0.38 -0.38 -0.38 s -0.38 0.17 -0.38 0.38 c 0 0.08 0.03 0.15 0.07 0.21 l -0.5 0.46 l 0.69 0.52 l 
		   -0.37 1.01 h -0.59 c -0.66 0 -1.19 0.51 -1.24 1.16 c -1.35 0.06 -2.7 0.16 -4.07 0.32 c -0.23 0.03 -0.41 0.21 
		   -0.44 0.44 c -0.04 0.41 -0.07 0.81 -0.09 1.21 c -0.29 0.18 -0.48 0.48 -0.48 0.82 l -0.02 1.48 c 0 0.34 0.17 
		   0.64 0.45 0.83 c 0.02 0.77 0.07 1.55 0.15 2.35 c 0.02 0.23 0.21 0.42 0.44 0.44 c 4.19 0.5 8.27 0.5 12.45 0 c 
		   0.23 -0.03 0.41 -0.21 0.44 -0.44 Z m -9.4 -1.96 c -0.85 0 -1.54 -0.69 -1.54 -1.54 s 0.69 -1.54 1.54 -1.54 s 
		   1.54 0.69 1.54 1.54 s -0.69 1.54 -1.54 1.54 Z m 5.47 0 c -0.85 0 -1.54 -0.69 -1.54 -1.54 s 0.69 -1.54 1.54 
		   -1.54 s 1.54 0.69 1.54 1.54 s -0.69 1.54 -1.54 1.54 Z"
		/>
	</SvgIcon>
)
