export const CUSTOM_KEYBOARD_LAYERS = {
	'math-numeric-solution': `
		<div class='rows'>
			<ul>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='keycap' data-insert='\\frac{#0}{\\placeholder{}}'>&divide;</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\cdot '>&#x22c5;<aside>centered dot</aside></li>
				<li class='keycap tex' data-latex='\\times 10^{\\placeholder{}}'></li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\exponentialE'>e<aside>exp e</aside></li>
				<li class='keycap tex' data-alt-keys='infinity' data-insert='\\infty '>&#x221e;</li>
				<li class='keycap tex' data-latex='\\pi'></li>
			</ul>
			<ul>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='keycap' data-insert='\\times'>&times;</li>
				<li class='separator w5'></li>
				<li class='keycap tex'>(</li>
				<li class='keycap tex'>)</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='keycap tex' data-insert='^{\\placeholder{}}'><span><i>x</i><sup>&thinsp;<small>&#x2b1a;</small></sup></span></li>
				<li class='keycap tex' data-insert='\\sqrt{#0}' data-latex='\\sqrt{#0}'></li>
			</ul>
			<ul>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='keycap' data-key='-'>&#x2212;</li>
				<li class='separator'></li>
				<li class='separator'></li>
				<li class='separator w5'></li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\emptyset '>&#x2205;<aside>empty set</aside></li>
				<li class='keycap tex' data-key='!'>!<aside>factorial</aside></li>
				<li class='action font-glyph bottom right' data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li></ul>
			</ul>
			<ul>
				<li class='keycap'>0</li>
				<li class='keycap'>.</li>
				<li class='keycap'>=</li>
				<li class='keycap'>+</li>
				<li class='separator'></li>
				<li class='separator'></li>
				<li class='separator w5'></li>
				<li class='separator w5'></li>
				<arrows/>
			</ul>
		</div>
	`,
	'math-no-styles': `
		<div class='rows'>
			<ul>
				<li class='keycap tex' data-alt-keys='x-var'><i>x</i></li>
				<li class='keycap tex' data-alt-keys='n-var'><i>n</i></li>
				<li class='separator w5'></li>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='keycap' data-insert='\\frac{#0}{\\placeholder{}}' data-alt-keys='/'>&divide;</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\exponentialE' data-alt-keys='ee'>e</li>
				<li class='keycap tex' data-insert='\\imaginaryI' data-alt-keys='ii'>i</li>
				<li class='keycap tex' data-latex='\\pi' data-alt-keys='numeric-pi'></li>
			</ul>
			<ul>
				<li class='keycap tex' data-key='<' data-alt-keys='<'>&lt;</li>
				<li class='keycap tex' data-key='>' data-alt-keys='>'>&gt;</li>
				<li class='separator w5'></li>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='keycap' data-insert='\\times' data-alt-keys='*'>&times;</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='keycap tex' data-alt-keys='^' data-insert='^{\\placeholder{}}'><span><i>x</i><sup>&thinsp;<small>&#x2b1a;</small></sup></span></li>
				<li class='keycap tex' data-insert='\\sqrt{#0}' data-latex='\\sqrt{#0}'></li>
			</ul>
			<ul>
				<li class='keycap tex' data-alt-keys='(' >(</li>
				<li class='keycap tex' data-alt-keys=')' >)</li>
				<li class='separator w5'></li>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='keycap' data-key='-' data-alt-keys='*'>&#x2212;</li>
				<li class='separator w5'></li>
				<li class='keycap tex small' data-alt-keys='int' data-latex='\\int_0^\\infty'><span></span></li>
				<li class='keycap tex' data-latex='\\forall' data-alt-keys='logic' ></li>
				<li class='action font-glyph bottom right' data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li></ul>
			</ul>
			<ul>
				<li class='separator'></li>
				<li class='separator'></li>
				<li class='separator w5'></li>
				<li class='keycap' data-alt-keys='0'>0</li>
				<li class='keycap' data-alt-keys='.'>.</li>
				<li class='keycap' data-alt-keys='='>=</li>
				<li class='keycap' data-alt-keys='+'>+</li>
				<li class='separator w5'></li>
				<arrows/>
			</ul>
		</div>
	`,
	'math-variable': `
		<div class='rows'>
			<ul>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='fnbutton' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
			</ul>
			<ul>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='keycap'>,</li>
			</ul>
			<ul>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='keycap' data-key=' '>&nbsp;</li>
			</ul>
			<ul>
				<li class='keycap'>0</li>
				<arrows/>
			</ul>
		</div>
	`,
	'math-numeric-answer': `
		<div class='rows wide-mobile'>
			<ul>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='keycap tex' data-alt-keys='infinity' data-insert='\\infty '>&#x221e;</li>
				<li class='keycap tex' data-insert='\\emptyset '>&#x2205;<aside>empty set</aside></li>
			</ul>
			<ul>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='keycap tex fnbutton' data-latex='\\times 10^{\\placeholder{}}'></li>
				<li class='keycap'>E</li>
			</ul>
			<ul>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='keycap' data-key='-'>&#x2212;</li>
				<li class='action font-glyph bottom right' data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li>
			</ul>
			<ul>
				<li class='keycap'>0</li>
				<li class='keycap'>.</li>
				<arrows/>
			</ul>
		</div>
	`,
	'math-complex-numeric-answer': `
		<div class='rows wide-mobile'>
			<ul>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='keycap tex' data-latex='\\imaginaryI'></li>
				<li class='keycap tex' data-latex='\\imaginaryJ'></li>
				<li class='keycap tex' data-alt-keys='infinity' data-insert='\\infty '>&#x221e;</li>
				<li class='keycap tex' data-insert='\\emptyset '>&#x2205;<aside>empty set</aside></li>
			</ul>
			<ul>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='keycap tex' data-insert="\\angle" data-latex='\\angle'></li>
				<li class='keycap tex' data-insert="\\degree" data-latex='\\degree'></li>
				<li class='keycap tex fnbutton' data-latex='\\times 10^{\\placeholder{}}'></li>
				<li class='keycap'>E</li>
			</ul>
			<ul>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='keycap tex' data-latex='\\pi'></li>
				<li class='keycap' data-key='+'>&#x2B;</li>
				<li class='keycap' data-key='-'>&#x2212;</li>
				<li class='action font-glyph bottom right' data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li>
			</ul>
			<ul>
				<li class='keycap'>0</li>
				<li class='keycap'>.</li>
				<li class='separator'></li>
				<li class='separator'></li>
				<arrows/>
			</ul>
		</div>
	`,
	'math-expression': `
		<div class='rows'>
		<ul>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='keycap' data-insert='\\frac{#0}{\\placeholder{}}'>&divide;</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\cdot '>&#x22c5;<aside>centered dot</aside></li>
				<li class='keycap tex' data-latex='\\times 10^{\\placeholder{}}'></li>
				<li class='keycap tex' data-insert='\\bar{#@}' data-latex='\\bar{\\placeholder{}}' data-aside='bar'></li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\exponentialE'>e<aside>exp e</aside></li>
				<li class='keycap tex' data-alt-keys='infinity' data-insert='\\infty '>&#x221e;</li>
				<li class='keycap tex' data-latex='\\pi'></li>
				</ul>
				<ul>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='keycap' data-insert='\\times'>&times;</li>
				<li class='separator w5'></li>
				<li class='keycap tex'>(</li>
				<li class='keycap tex'>)</li>
				<li class='keycap tex' data-insert='\\overline{#@}' data-latex='\\overline{\\placeholder{}}' data-aside='overline'></li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='keycap tex' data-insert='^{\\placeholder{}}'><span><i>x</i><sup>&thinsp;<small>&#x2b1a;</small></sup></span></li>
				<li class='keycap tex' data-insert='\\sqrt{#0}' data-latex='\\sqrt{#0}'></li>
			</ul>
			<ul>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='keycap' data-key='-'>&#x2212;</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-latex='\\$'></li>
				<li class='keycap tex' data-latex='\\%'></li>
				<li class='keycap tex' data-latex='\\neq'></li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\emptyset '>&#x2205;<aside>empty set</aside></li>
				<li class='keycap tex' data-key='!'>!<aside>factorial</aside></li>
				<li class='action font-glyph bottom right' data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li></ul>
			</ul>
			<ul>
				<li class='keycap'>0</li>
				<li class='keycap'>.</li>
				<li class='keycap'>=</li>
				<li class='keycap'>+</li>
				<li class='separator w5'></li>
				<li class='separator w20'></li>
				<li class='separator w5'></li>
				<li class='separator'></li>
				<arrows/>
			</ul>
		</div>
	`,
	'lower-roman-no-extras': `
		<div class='rows'>
			<ul>
				<li class='keycap'>q</li>
				<li class='keycap'>w</li>
				<li class='keycap'>e</li>
				<li class='keycap'>r</li>
				<li class='keycap'>t</li>
				<li class='keycap'>y</li>
				<li class='keycap'>u</li>
				<li class='keycap'>i</li>
				<li class='keycap'>o</li>
				<li class='keycap'>p</li>
			</ul>
			<ul>
				<li class='separator w5'></li>
				<li class='keycap'>a</li>
				<li class='keycap'>s</li>
				<li class='keycap'>d</li>
				<li class='keycap'>f</li>
				<li class='keycap'>g</li>
				<li class='keycap'>h</li>
				<li class='keycap'>j</li>
				<li class='keycap'>k</li>
				<li class='keycap'>l</li>
				<li class='separator w5'></li>
			</ul>
			<ul>
				<li class='shift modifier font-glyph bottom left w15 layer-switch' data-layer='upper-roman-no-extras'>&#x21e7;</li>
				<li class='keycap'>z</li>
				<li class='keycap'>x</li>
				<li class='keycap'>c</li>
				<li class='keycap'>v</li>
				<li class='keycap'>b</li>
				<li class='keycap'>n</li>
				<li class='keycap'>m</li>
				<li class='action font-glyph bottom right w15' data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>' 
					data-shifted-command='"deleteAll"' 
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
					>&#x232b;</li>
			</ul>
			<ul>
				<li class='fnbutton' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='keycap'>,</li>
				<li class='keycap w50' data-key=' ' data-alt-keys='space'>&nbsp;</li>
				<arrows/>
			</ul>
		</div>`,
	'lower-roman-no-styles': `
		<div class='rows'>
			<ul>
				<li class='keycap'>q</li>
				<li class='keycap'>w</li>
				<li class='keycap'>e</li>
				<li class='keycap'>r</li>
				<li class='keycap'>t</li>
				<li class='keycap'>y</li>
				<li class='keycap'>u</li>
				<li class='keycap'>i</li>
				<li class='keycap'>o</li>
				<li class='keycap'>p</li>
			</ul>
			<ul>
				<li class='separator w5'></li>
				<li class='keycap'>a</li>
				<li class='keycap'>s</li>
				<li class='keycap'>d</li>
				<li class='keycap'>f</li>
				<li class='keycap'>g</li>
				<li class='keycap'>h</li>
				<li class='keycap'>j</li>
				<li class='keycap'>k</li>
				<li class='keycap'>l</li>
				<li class='separator w5'></li>
			</ul>
			<ul>
				<li class='shift modifier font-glyph bottom left w15 layer-switch' data-layer='upper-roman-no-styles'>&#x21e7;</li>
				<li class='keycap'>z</li>
				<li class='keycap'>x</li>
				<li class='keycap'>c</li>
				<li class='keycap'>v</li>
				<li class='keycap'>b</li>
				<li class='keycap'>n</li>
				<li class='keycap'>m</li>
				<li class='action font-glyph bottom right w15' data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>' 
					data-shifted-command='"deleteAll"' 
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
					>&#x232b;</li>
			</ul>
			<ul>
				<li class='layer-switch font-glyph modifier bottom left' data-layer='symbols-no-numpad'>&infin;≠</li>
				<li class='keycap'>,</li>
				<li class='keycap w50' data-key=' ' data-alt-keys='space'>&nbsp;</li>
				<arrows/>
			</ul>
		</div>`,
	'upper-roman-no-extras': `
		<div class='rows'>
			<ul>
				<li class='keycap'>Q</li>
				<li class='keycap'>W</li>
				<li class='keycap'>E</li>
				<li class='keycap'>R</li>
				<li class='keycap'>T</li>
				<li class='keycap'>Y</li>
				<li class='keycap'>U</li>
				<li class='keycap'>I</li>
				<li class='keycap'>O</li>
				<li class='keycap'>P</li>
			</ul>
			<ul>
				<li class='separator w5'></li>
				<li class='keycap'>A</li>
				<li class='keycap'>S</li>
				<li class='keycap'>D</li>
				<li class='keycap'>F</li>
				<li class='keycap'>G</li>
				<li class='keycap'>H</li>
				<li class='keycap'>J</li>
				<li class='keycap'>K</li>
				<li class='keycap'>L</li>
				<li class='separator w5'></li>
			</ul>
			<ul>
				<li class='shift modifier font-glyph bottom left w15 layer-switch' data-layer='lower-roman-no-extras'>&#x21e7;</li>
				<li class='keycap'>Z</li>
				<li class='keycap'>X</li>
				<li class='keycap'>C</li>
				<li class='keycap'>V</li>
				<li class='keycap'>B</li>
				<li class='keycap'>N</li>
				<li class='keycap'>M</li>
				<li class='action font-glyph bottom right w15' data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>' 
					data-shifted-command='"deleteAll"' 
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
					>&#x232b;</li>
			</ul>
			<ul>
				<li class='fnbutton' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='keycap'>,</li>
				<arrows/>
			</ul>
		</div>`,
	'upper-roman-no-styles': `
		<div class='rows'>
			<ul>
				<li class='keycap'>Q</li>
				<li class='keycap'>W</li>
				<li class='keycap'>E</li>
				<li class='keycap'>R</li>
				<li class='keycap'>T</li>
				<li class='keycap'>Y</li>
				<li class='keycap'>U</li>
				<li class='keycap'>I</li>
				<li class='keycap'>O</li>
				<li class='keycap'>P</li>
			</ul>
			<ul>
				<li class='separator w5'></li>
				<li class='keycap'>A</li>
				<li class='keycap'>S</li>
				<li class='keycap'>D</li>
				<li class='keycap'>F</li>
				<li class='keycap'>G</li>
				<li class='keycap'>H</li>
				<li class='keycap'>J</li>
				<li class='keycap'>K</li>
				<li class='keycap'>L</li>
				<li class='separator w5'></li>
			</ul>
			<ul>
				<li class='shift modifier font-glyph bottom left w15 layer-switch' data-layer='lower-roman-no-styles'>&#x21e7;</li>
				<li class='keycap'>Z</li>
				<li class='keycap'>X</li>
				<li class='keycap'>C</li>
				<li class='keycap'>V</li>
				<li class='keycap'>B</li>
				<li class='keycap'>N</li>
				<li class='keycap'>M</li>
				<li class='action font-glyph bottom right w15' data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>' 
					data-shifted-command='"deleteAll"' 
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
					>&#x232b;</li>
			</ul>
			<ul>
				<li class='layer-switch font-glyph modifier bottom left' data-layer='symbols-no-numpad'>&infin;≠</li>
				<li class='keycap' data-alt-keys='.'>;</li>
				<li class='keycap w50' data-key=' '>&nbsp;</li>
				<arrows/>
			</ul>
		</div>`,
	// replace the default, to always show "aside" names
	'lower-greek': `
		<div class='rows'>
			<ul>
				<li class='keycap tex' data-insert='\\varphi '><i>&#x03c6;</i><aside>phi var.</aside></li>
				<li class='keycap tex' data-insert='\\varsigma '><i>&#x03c2;</i><aside>sigma var.</aside></li>
				<li class='keycap tex' data-insert='\\epsilon '><i>&#x03f5;</i><aside>epsilon</aside></li>
				<li class='keycap tex' data-insert='\\rho '><i>&rho;</i><aside>rho</aside></li>
				<li class='keycap tex' data-insert='\\tau '><i>&tau;</i><aside>tau</aside></li>
				<li class='keycap tex' data-insert='\\upsilon '><i>&upsilon;</i><aside>upsilon</aside></li>
				<li class='keycap tex' data-insert='\\theta '><i>&theta;</i><aside>theta</aside></li>
				<li class='keycap tex' data-insert='\\iota '><i>&iota;</i><aside>iota</aside></li>
				<li class='keycap tex' data-insert='\\omicron '>&omicron;</i><aside>omicron</aside></li>
				<li class='keycap tex' data-insert='\\pi '><i>&pi;</i><aside>pi</aside></li>
			</ul>
			<ul>
				<li class='keycap tex' data-insert='\\alpha ' data-shifted='&Alpha;' data-shifted-command='["insert","{\\\\char\\"391}"]'><i>&alpha;</i><aside>alpha</aside></li>
				<li class='keycap tex' data-insert='\\sigma '><i>&sigma;</i><aside>sigma</aside></li>
				<li class='keycap tex' data-insert='\\delta '><i>&delta;</i><aside>delta</aside></li>
				<li class='keycap tex' data-insert='\\phi '><i>&#x03d5;</i></i><aside>phi</aside></li>
				<li class='keycap tex' data-insert='\\gamma '><i>&gamma;</i><aside>gamma</aside></li>
				<li class='keycap tex' data-insert='\\eta '><i>&eta;</i><aside>eta</aside></li>
				<li class='keycap tex' data-insert='\\xi '><i>&xi;</i><aside>xi</aside></li>
				<li class='keycap tex' data-insert='\\kappa '><i>&kappa;</i><aside>kappa</aside></li>
				<li class='keycap tex' data-insert='\\lambda '><i>&lambda;</i><aside>lambda</aside></li>
			</ul>
			<ul>
				<li class='shift modifier font-glyph bottom left w15 layer-switch' data-layer='upper-greek'>&#x21e7;</li>
				<li class='keycap tex' data-insert='\\zeta '><i>&zeta;</i><aside>zeta</aside></li>
				<li class='keycap tex' data-insert='\\chi '><i>&chi;</i><aside>chi</aside></li>
				<li class='keycap tex' data-insert='\\psi '><i>&psi;</i><aside>psi</aside></li>
				<li class='keycap tex' data-insert='\\omega '><i>&omega;</i><aside>omega</aside></li>
				<li class='keycap tex' data-insert='\\beta '><i>&beta;</i><aside>beta</aside></li>
				<li class='keycap tex' data-insert='\\nu '><i>&nu;</i><aside>nu</aside></li>
				<li class='keycap tex' data-insert='\\mu '><i>&mu;</i><aside>mu</aside></li>
				<li class='action font-glyph bottom right w15'
					data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>'
					data-shifted-command='"deleteAll"'
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
				>&#x232b;</li>
			</ul>
			<ul>
				<li class='fnbutton' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='keycap'>,</li>
				<li class='keycap tex' data-insert='\\varepsilon '><i>&#x03b5;</i><aside>epsilon var.</aside></li>
				<li class='keycap tex' data-insert='\\vartheta '><i>&#x03d1;</i><aside>theta var.</aside></li>
				<li class='keycap tex' data-insert='\\varkappa '><i>&#x3f0;</i><aside>kappa var.</aside></li>
				<li class='keycap tex' data-insert='\\varpi '><i>&#x03d6;<aside>pi var.</aside></i></li>
				<li class='keycap tex' data-insert='\\varrho '><i>&#x03f1;</i><aside>rho var.</aside></li>
				<arrows/>
			</ul>
		</div>`,
	'functions-numeric-solution': `
		<div class='rows'>
			<ul>
				<li class='fnbutton' data-insert='\\sin'></li>
				<li class='fnbutton' data-insert='\\sin^{-1}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\ln'></li>
				<li class='fnbutton' data-insert='\\exponentialE^{\\placeholder{}}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton small' data-insert='\\operatorname{lcm}(\\placeholder{},\\placeholder{})' data-latex='\\operatorname{lcm}()'></li>
				<li class='fnbutton small' data-insert='\\operatorname{ceil}(\\placeholder{})' data-latex='\\operatorname{ceil}()'></li>
				<li class='separator w5'></li>
				<li class='bigfnbutton' data-insert='\\int'></li>
				<li class='bigfnbutton' data-insert='\\int_{\\placeholder{}}^{\\placeholder{}}'></li>
				<li class='bigfnbutton' data-insert='\\lim_{n\\to\\infty}'></li>
			</ul>
			<ul>
				<li class='fnbutton' data-insert='\\cos'></li>
				<li class='fnbutton' data-insert='\\cos^{-1}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\log_{10}'></li>
				<li class='fnbutton' data-insert='10^{\\placeholder{}}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton small' data-insert='\\operatorname{gcd}(\\placeholder{},\\placeholder{})' data-latex='\\operatorname{gcd}()'></li>
				<li class='fnbutton small' data-insert='\\operatorname{floor}(\\placeholder{})' data-latex='\\operatorname{floor}()'></li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\differentialD'>d<aside>diff d</aside></li>
				<li class='bigfnbutton' data-insert='\\frac{\\differentialD #0}{\\differentialD x}'></li>
				<li class='bigfnbutton' data-insert='\\sum_{n=0}^{\\placeholder{}}'></li>
			</ul>
			<ul>
				<li class='fnbutton' data-insert='\\tan'></li>
				<li class='fnbutton' data-insert='\\tan^{-1}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\log_{\\placeholder{}}'></li>
				<li class='fnbutton' data-insert='\\sqrt[\\placeholder{}]{#0}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton small' data-insert='\\max(\\placeholder{},\\placeholder{})' data-latex='\\max()'></li>
				<li class='fnbutton small' data-insert='\\min(\\placeholder{},\\placeholder{})' data-latex='\\min()'></li>
				<li class='separator w5'></li>
				<li class='bigfnbutton' data-insert='\\prod_{n=0}^{\\placeholder{}}' data-latex='{\\tiny \\prod_{n=0}^{\\placeholder{}}}'></li>
				<li class='bigfnbutton' data-insert='\\prod_{n=0}^{\\infty}' data-latex='{\\tiny \\prod_{n=0}^{\\infty}}'></li>
				<li class='action font-glyph bottom right' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li>
			</ul>
			<ul>
				<li class='fnbutton'>(</li>
				<li class='fnbutton'>)</li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='^{\\placeholder{}} ' data-latex='x^{\\placeholder{}} '></li>
				<li class='fnbutton' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='separator w5'></li>
				<li class='fnbutton small' data-insert='\\mod' data-latex='\\mod'></li>
				<li class='separator'></li>
				<li class='separator w5'></li>
				<arrows/>
			</ul>
		</div>`,
	'functions-expression': `
		<div class='rows'>
			<ul>
				<li class='keycap tex' data-key='<'>&lt;</li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\sin'></li>
				<li class='fnbutton' data-insert='\\sin^{-1}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\ln'></li>
				<li class='fnbutton' data-insert='\\exponentialE^{\\placeholder{}}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton small' data-insert='\\min(\\placeholder{},\\placeholder{})' data-latex='\\min()'></li>
				<li class='fnbutton small' data-insert='\\operatorname{ceil}(\\placeholder{})' data-latex='\\operatorname{ceil}()'></li>
				<li class='separator w5'></li>
				<li class='bigfnbutton' data-insert='\\int'></li>
				<li class='bigfnbutton' data-insert='\\int_{\\placeholder{}}^{\\placeholder{}}'></li>
				<li class='bigfnbutton' data-insert='\\lim_{n\\to\\infty}'></li>
			</ul>
			<ul>
				<li class='keycap tex' data-key='>'>&gt;</li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\cos'></li>
				<li class='fnbutton' data-insert='\\cos^{-1}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\log_{10}'></li>
				<li class='fnbutton' data-insert='10^{\\placeholder{}}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton small' data-insert='\\max(\\placeholder{},\\placeholder{})' data-latex='\\max()'></li>
				<li class='fnbutton small' data-insert='\\operatorname{floor}(\\placeholder{})' data-latex='\\operatorname{floor}()'></li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\differentialD'>d<aside>diff d</aside></li>
				<li class='bigfnbutton' data-insert='\\frac{\\differentialD #0}{\\differentialD x}'></li>
				<li class='bigfnbutton' data-insert='\\sum_{n=0}^{\\placeholder{}}'></li>
			</ul>
			<ul>
				<li class='keycap tex' data-insert='\\leq' data-latex='\\leq'></li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\tan'></li>
				<li class='fnbutton' data-insert='\\tan^{-1}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='\\log_{\\placeholder{}}'></li>
				<li class='fnbutton' data-insert='\\sqrt[\\placeholder{}]{#0}'></li>
				<li class='separator w5'></li>
				<li class='fnbutton small' data-insert='\\mod' data-latex='\\mod'></li>
				<li class='separator'></li>
				<li class='separator w5'></li>
				<li class='bigfnbutton' data-insert='\\prod_{n=0}^{\\placeholder{}}' data-latex='{\\tiny \\prod_{n=0}^{\\placeholder{}}}'></li>
				<li class='bigfnbutton' data-insert='\\prod_{n=0}^{\\infty}' data-latex='{\\tiny \\prod_{n=0}^{\\infty}}'></li>
				<li class='action font-glyph bottom right' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li>
			</ul>
			<ul>
				<li class='keycap tex' data-insert='\\geq' data-latex='\\geq'></li>
				<li class='separator w5'></li>
				<li class='fnbutton'>(</li>
				<li class='fnbutton'>)</li>
				<li class='separator w5'></li>
				<li class='fnbutton' data-insert='^{\\placeholder{}} ' data-latex='x^{\\placeholder{}} '></li>
				<li class='fnbutton' data-insert='_{\\placeholder{}} ' data-latex='x_{\\placeholder{}} '></li>
				<li class='separator w5'></li>
				<li class='separator'></li>
				<li class='separator w5'></li>
				<li class='separator'></li>
				<arrows/>
			</ul>
		</div>`,
	'symbols-no-numpad': `
		<div class='rows'>
			<ul>
				<li class='keycap tex' data-alt-keys='(' data-insert='\\lbrace '>{</li>
				<li class='keycap tex' data-alt-keys=')' data-insert='\\rbrace '>}</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-alt-keys='set' data-insert='\\in '>&#x2208;</li>
				<li class='keycap tex' data-alt-keys='!set' data-insert='\\notin '>&#x2209;</li>
				<li class='keycap tex' data-insert='\\Re '>&#x211c;<aside>Real</aside></li>
				<li class='keycap tex' data-insert='\\Im '>&#x2111;<aside>Imaginary</aside></li>
				<li class='keycap w15' data-insert='\\ulcorner#0\\urcorner '><span><sup>&#x250c;</sup><span><span style='color:#ddd'>o</span><sup>&#x2510;</sup></span><aside>ceil</aside></li>
				<li class='keycap tex' data-alt-keys='degree' data-insert='\\degree '>&#xb0;<aside>degree</aside></li>
				<li class='keycap tex' data-alt-keys='infinity' data-insert='\\infty '>&#x221e;</li>

			</ul>
			<ul>
				<li class='keycap tex' data-alt-keys='(' data-insert='\\lbrack '>[</li>
				<li class='keycap tex' data-alt-keys=')' data-insert='\\rbrack '>]</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-alt-keys='subset' data-insert='\\subset '>&#x2282;</li>
				<li class='keycap tex' data-alt-keys='supset' data-insert='\\supset '>&#x2283;</li>
				<li class='keycap tex' data-key='!' data-alt-keys='!'>!<aside>factorial</aside></li>
				<li class='keycap' data-insert='^{\\prime} '><span><sup><span><span style='color:#ddd'>o</span>&#x2032</sup></span><aside>prime</aside></li>
				<li class='keycap w15' data-insert='\\llcorner#0\\lrcorner '><span><sub>&#x2514;</sub><span style='color:#ddd'>o</span><sub>&#x2518;</sub></span><aside>floor</aside></li>
				<li class='keycap tex' data-insert='\\partial '>&#x2202;<aside>partial<br>derivative</aside></li>
				<li class='keycap tex' data-insert='\\emptyset '>&#x2205;<aside>empty set</aside></li>
			</ul>
			<ul>
				<li class='keycap tex' data-alt-keys='(' data-insert='\\langle '>&#x27e8;</li>
				<li class='keycap tex' data-alt-keys=')' data-insert='\\rangle '>&#x27e9;</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-insert='\\subseteq '>&#x2286;</li>
				<li class='keycap tex' data-insert='\\supseteq '>&#x2287;</li>
				<li class='keycap tex' data-alt-keys='accents' data-insert='\\vec{#@}' data-latex='\\vec{\\placeholder{}}' data-aside='vector'></li>
				<li class='keycap tex' data-alt-keys='accents' data-insert='\\bar{#@}' data-latex='\\bar{\\placeholder{}}' data-aside='bar'></li>
				<li class='keycap tex' data-alt-keys='absnorm' data-insert='\\lvert #@ \\rvert ' data-latex='\\lvert \\placeholder{} \\rvert' data-aside='abs'></li>
				<li class='keycap tex' data-insert='\\ast '>&#x2217;<aside>asterisk</aside></li>

				<li class='action font-glyph bottom right w15'
					data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>'
					data-shifted-command='"deleteAll"'
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
				>&#x232b;</li>
			</ul>
			<ul>
				<li class='layer-switch font-glyph modifier bottom left' data-layer='lower-roman-no-styles'>abc</li>
				<li class='keycap tex' data-insert='\\cdot '>&#x22c5;<aside>centered dot</aside></li>
				<li class='keycap tex' data-insert='\\colon '>:<aside>colon</aside></li>
				<li class='keycap tex' data-insert='\\circ '>&#x2218;<aside>circle</aside></li>
				<li class='keycap tex' data-insert='\\approx '>&#x2248;<aside>approx.</aside></li>
				<li class='keycap tex' data-insert='\\ne '>&#x2260;</li>
				<li class='keycap tex' data-insert='\\pm '>&#x00b1;</li>
				<arrows/>
			</ul>
		</div>`,
	'math-complex-numbers': `
		<div class='rows'>
		<ul>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='keycap' data-insert='\\frac{#0}{\\placeholder{}}'>&divide;</li>
				<li class='separator w5'></li>
				<li class='keycap tex' data-latex='\\imaginaryI'></li>
				<li class='keycap tex' data-latex='\\imaginaryJ'></li>
				<li class='keycap tex' data-insert="\\angle" data-latex='\\angle'></li>
				<li class='keycap tex' data-insert="\\degree" data-latex='\\degree'></li>
			</ul>
			<ul>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='keycap' data-insert='\\times'>&times;</li>
				<li class='separator w5'></li>
				<li class="fnbutton small" data-insert='\\operatorname{Re}(\\placeholder{})' data-latex='\\operatorname{Re}()'></li>
				<li class="fnbutton small" data-insert='\\operatorname{Im}(\\placeholder{})' data-latex='\\operatorname{Im}()'></li>
				<li class='keycap tex' data-alt-keys='(' >(</li>
				<li class='keycap tex' data-alt-keys=')' >)</li>
			</ul>
			<ul>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='keycap' data-key='-'>&#x2212;</li>
				<li class='separator w5'></li>
				<li class="fnbutton small" data-insert='\\operatorname{Abs}(\\placeholder{})' data-latex='\\operatorname{Abs}()'></li>
				<li class="fnbutton small" data-insert='\\operatorname{Arg}(\\placeholder{})' data-latex='\\operatorname{Arg}()'></li>
				<li class='keycap tex' data-latex='\\pi'></li>
				<li class='action font-glyph bottom right' data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li>
			</ul>
			<ul>
				<li class='keycap'>0</li>
				<li class='keycap'>.</li>
				<li class='keycap'>=</li>
				<li class='keycap' data-key='+'>&#x2B;</li>
				<li class='separator w5'></li>
				<li class="fnbutton small" data-insert='\\operatorname{conj}(\\placeholder{})' data-latex='\\operatorname{conj}()'></li>
				<arrows/>
			</ul>
		</div>`,
	// unit keyboard layers
	'lower-letters-unit': `
		<div class='rows'>
			<ul>
				<li class='keycap'>q</li>
				<li class='keycap'>w</li>
				<li class='keycap'>e</li>
				<li class='keycap'>r</li>
				<li class='keycap'>t</li>
				<li class='keycap'>y</li>
				<li class='keycap'>u</li>
				<li class='keycap'>i</li>
				<li class='keycap'>o</li>
				<li class='keycap'>p</li>
			</ul>
			<ul>
				<li class='separator w5'></li>
				<li class='keycap'>a</li>
				<li class='keycap'>s</li>
				<li class='keycap'>d</li>
				<li class='keycap'>f</li>
				<li class='keycap'>g</li>
				<li class='keycap'>h</li>
				<li class='keycap'>j</li>
				<li class='keycap'>k</li>
				<li class='keycap'>l</li>
				<li class='separator w5'></li>
			</ul>
			<ul>
				<li class='shift modifier font-glyph bottom left w15 layer-switch' data-layer='upper-letters-unit'>&#x21e7;</li>
				<li class='keycap'>z</li>
				<li class='keycap'>x</li>
				<li class='keycap'>c</li>
				<li class='keycap'>v</li>
				<li class='keycap'>b</li>
				<li class='keycap'>n</li>
				<li class='keycap'>m</li>
				<li class='action font-glyph bottom right w15' data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>' 
					data-shifted-command='"deleteAll"' 
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
					>&#x232b;</li>
			</ul>
			<ul>
				<li class='keycap tex' data-insert='\\mu '><i>&mu;</i><aside>mu</aside></li>
				<li class='keycap tex' data-insert='\\Omega '>&Omega;<aside>omega</aside></li>
				<li class='keycap w50' data-key=' ' data-alt-keys='space'>&nbsp;</li>
				<arrows/>
			</ul>
		</div>`,
	'upper-letters-unit': `
		<div class='rows'>
			<ul>
				<li class='keycap'>Q</li>
				<li class='keycap'>W</li>
				<li class='keycap'>E</li>
				<li class='keycap'>R</li>
				<li class='keycap'>T</li>
				<li class='keycap'>Y</li>
				<li class='keycap'>U</li>
				<li class='keycap'>I</li>
				<li class='keycap'>O</li>
				<li class='keycap'>P</li>
			</ul>
			<ul>
				<li class='separator w5'></li>
				<li class='keycap'>A</li>
				<li class='keycap'>S</li>
				<li class='keycap'>D</li>
				<li class='keycap'>F</li>
				<li class='keycap'>G</li>
				<li class='keycap'>H</li>
				<li class='keycap'>J</li>
				<li class='keycap'>K</li>
				<li class='keycap'>L</li>
				<li class='separator w5'></li>
			</ul>
			<ul>
				<li class='shift font-glyph bottom left w15 layer-switch' data-layer='lower-letters-unit'>&#x21e7;</li>
				<li class='keycap'>Z</li>
				<li class='keycap'>X</li>
				<li class='keycap'>C</li>
				<li class='keycap'>V</li>
				<li class='keycap'>B</li>
				<li class='keycap'>N</li>
				<li class='keycap'>M</li>
				<li class='action font-glyph bottom right w15' data-shifted='<span class="warning"><svg><use xlink:href="#svg-trash" /></svg></span>' 
					data-shifted-command='"deleteAll"' 
					data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'
					>&#x232b;</li>
			</ul>
			<ul>
				<li class='keycap tex' data-insert='\\mu '><i>&mu;</i><aside>mu</aside></li>
				<li class='keycap tex' data-insert='\\Omega '>&Omega;<aside>omega</aside></li>
				<li class='keycap w50' data-key=' '>&nbsp;</li>
				<arrows/>
			</ul>
		</div>`,
	'math-unit': `
		<div class='rows wide-mobile'>
			<ul>
				<li class='keycap'>7</li>
				<li class='keycap'>8</li>
				<li class='keycap'>9</li>
				<li class='separator w5'></li>
				<li class='keycap' data-insert='\\frac{#0}{\\placeholder{}}'>&divide;</li>
				<li class='keycap tex'>(</li>
				<li class='keycap tex'>)</li>
			</ul>
			<ul>
				<li class='keycap'>4</li>
				<li class='keycap'>5</li>
				<li class='keycap'>6</li>
				<li class='separator w5'></li>
				<li class='keycap' data-insert='\\times'>&times;</li>
				<li class='keycap tex' data-alt-keys='degree' data-insert='\\degree '>&#xb0;<aside>degree</aside></li>
				<li class='keycap tex' data-latex='\\%'></li>
			</ul>
			<ul>
				<li class='keycap'>1</li>
				<li class='keycap'>2</li>
				<li class='keycap'>3</li>
				<li class='separator w5'></li>
				<li class='keycap' data-key='-'>&#x2212;</li>
				<li class='keycap tex' data-insert='^{\\placeholder{}}'><span><i>x</i><sup>&thinsp;<small>&#x2b1a;</small></sup></span></li>
				<li class='action font-glyph bottom right' data-alt-keys='delete' data-command='["performWithFeedback","deletePreviousChar"]'>&#x232b;</li>
			</ul>
			<ul>
				<li class='keycap w20'>0</li>
				<li class='separator'></li>
				<li class='separator w5'></li>
				<arrows/>
			</ul>
		</div>
	`
}

export const CUSTOM_KEYBOARDS = {
	'math-numeric-solution': {
		tooltip: 'keyboard.tooltip.numeric',
		layer: 'math-numeric-solution',
		label: '123',
		layers: ['math-numeric-solution']
	},
	'math-no-styles': {
		tooltip: 'keyboard.tooltip.numeric',
		layer: 'math-no-styles',
		label: '123',
		layers: ['math-no-styles']
	},
	'math-variable': {
		tooltip: 'keyboard.tooltip.numeric',
		layer: 'math-variable',
		label: '123',
		layers: ['math-variable']
	},
	'math-expression': {
		tooltip: 'keyboard.tooltip.numeric',
		layer: 'math-expression',
		label: '123',
		layers: ['math-expression']
	},
	'roman-no-extras': {
		tooltip: 'keyboard.tooltip.roman',
		layer: 'lower-roman-no-extras',
		label: 'ABC',
		layers: ['lower-roman-no-extras', 'upper-roman-no-extras']
	},
	'roman-no-styles': {
		tooltip: 'keyboard.tooltip.roman',
		layer: 'lower-roman-no-styles',
		label: 'ABC',
		layers: ['lower-roman-no-styles', 'upper-roman-no-styles', 'symbols-no-numpad']
	},
	'functions-numeric-solution': {
		tooltip: 'keyboard.tooltip.functions',
		layer: 'functions-numeric-solution',
		label: '<i>f</i>&thinsp;()',
		classes: 'tex',
		layers: ['functions-numeric-solution']
	},
	'functions-expression': {
		tooltip: 'keyboard.tooltip.functions',
		layer: 'functions-expression',
		label: '<i>f</i>&thinsp;()',
		classes: 'tex',
		layers: ['functions-expression']
	},
	'math-numeric-answer': {
		tooltip: 'keyboard.tooltip.numeric',
		layer: 'math-numeric-answer',
		label: '123',
		layers: ['math-numeric-answer']
	},
	'math-complex-numeric-answer': {
		tooltip: 'Complex Numbers',
		layer: 'math-complex-numeric-answer',
		label: '<b>i</b>',
		classes: 'tex',
		layers: ['math-complex-numeric-answer']
	},
	'math-complex-numbers': {
		tooltip: 'Complex Numbers',
		layer: 'math-complex-numbers',
		label: '<b>i</b>',
		classes: 'tex',
		layers: ['math-complex-numbers']
	},
	'letters-unit': {
		tooltip: 'keyboard.tooltip.roman',
		layer: 'lower-letters-unit',
		label: 'ABC',
		layers: ['lower-letters-unit', 'upper-letters-unit']
	},
	'math-unit': {
		tooltip: 'keyboard.tooltip.numeric',
		layer: 'math-unit',
		label: '123',
		layers: ['math-unit']
	}
}

export const NUMERIC_SOLUTION_KEYBOARDS =
	'math-numeric-solution roman-no-extras greek functions-numeric-solution math-complex-numbers'

export const NO_STYLE_KEYBOARDS = 'math-no-styles roman-no-styles greek functions'

export const VARIABLE_KEYBOARDS = 'roman-no-extras greek math-variable'

export const NUMERIC_ANSWER_KEYBOARDS = 'math-numeric-answer'

export const UNIT_KEYBOARDS = 'letters-unit math-unit'

export const COMPLEX_NUMERIC_ANSWER_KEYBOARDS = 'math-complex-numeric-answer'

export const EXPRESSION_KEYBOARDS = 'math-expression roman-no-extras greek functions-expression math-complex-numbers'

export const INLINE_SYMBOLS = {
	// Infinity
	infinity: {
		mode: 'math',
		after: 'nothing+digit+frac+surd+binop+relop+punct+array+openfence+closefence+space',
		value: '\\infty'
	},
	oo: {
		mode: 'math',
		after: 'nothing+digit+frac+surd+binop+relop+punct+array+openfence+closefence+space',
		value: '\\infty'
	},

	// Empty set
	emptyset: { mode: 'math', value: '\\emptyset' },
	undefined: { mode: 'math', value: '\\emptyset' },

	// Letter-like
	ii: {
		value: '\\imaginaryI'
	},
	jj: {
		value: '\\imaginaryJ'
	},
	ee: {
		mode: 'math',
		value: '\\exponentialE'
	},
	dd: {
		mode: 'math',
		value: '\\differentialD'
	},

	nabla: { mode: 'math', value: '\\nabla' },
	grad: { mode: 'math', value: '\\nabla' },
	del: { mode: 'math', value: '\\partial' },
	degree: { mode: 'math', value: '\\degree' },
	angle: { mode: 'math', value: '\\angle' },
	$: '\\$',
	'%': '\\%',

	// '||': '\\lor', //
	// '||': '\\Vert' // CONFLICTS duplicate key \lor
	'...': '\\ldots', // In general, use \ldots
	'+...': '+\\cdots', // ... but use \cdots after + ...
	'-...': '-\\cdots', // ... - and ...
	'->...': '\\to\\cdots', // ->

	// Fences
	'(:': '\\langle',
	':)': '\\rangle',
	'{': '\\{',
	'}': '\\}',
	'|__': '\\lfloor',
	'__|': '\\rfloor',
	'|~': '\\lceil',
	'~|': '\\rceil',

	'->': '\\to',
	'-->': '\\longrightarrow',
	'=>': '\\Rightarrow',
	'==>': '\\Longrightarrow',
	'|->': '\\mapsto',
	// '<-': '\\leftarrow', //not working windows or mac
	'<--': '\\longleftarrow',
	// '<=': '\\Leftarrow', // CONFLICTS WITH LESS THAN OR EQUAL
	'<=>': '\\Leftrightarrow',
	'<->': '\\leftrightarrow',

	// Primes
	"''": { mode: 'math', value: '^{\\doubleprime}' },

	'*': '\\cdot',
	'(.)': '\\odot',
	'(+)': '\\oplus',
	'(/)': '\\oslash',
	'(*)': '\\otimes',
	'(-)': '\\ominus'
	// '(-)': '\\circleddash' // CONFLICTS duplicate key \ominus
}

export const INLINE_GREEK = {
	// Greek letters
	alpha: '\\alpha',
	beta: '\\beta',
	gamma: '\\gamma',
	Gamma: '\\Gamma',
	delta: '\\delta',
	Delta: '\\Delta',
	epsilon: '\\epsilon',
	varepsilon: '\\varepsilon',
	zeta: '\\zeta',
	eta: {
		mode: 'math',
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\eta'
	},
	theta: '\\theta',
	Theta: '\\Theta',
	vartheta: '\\vartheta',
	iota: '\\iota',
	kappa: '\\kappa',
	lambda: '\\lambda',
	Lambda: '\\Lambda',
	mu: {
		mode: 'math',
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\mu'
	},
	µ: '\\mu', // @TODO: or micro?
	nu: {
		mode: 'math',
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\nu'
	},
	xi: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\xi'
	},
	Xi: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\Xi'
	},
	pi: { mode: 'math', value: '\\pi' },
	π: '\\pi',
	Pi: '\\Pi',
	rho: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\rho'
	},
	sigma: '\\sigma',
	Sigma: '\\Sigma',
	tau: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\tau'
	},
	upsilon: '\\upsilon',
	phi: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\phi'
	},
	Phi: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\Phi'
	},
	varphi: '\\varphi',
	chi: '\\chi',
	psi: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\psi'
	},
	Psi: {
		after: 'nothing+digit+function+frac+surd+binop+relop+punct+array+openfence+closefence+space+text',
		value: '\\Psi'
	},
	omega: '\\omega',
	Omega: '\\Omega',
	Ω: '\\omega' // @TODO: or ohm?
}

export const INLINE_OPERATORS = {
	// Operators
	xx: '\\times',
	'+-': '\\pm',
	'^': '^{#?}',
	_: '_{#?}',

	// Relational operators
	'!=': '\\ne',
	'>=': '\\ge',
	'<=': '\\le',
	'<<': '\\ll',
	'>>': '\\gg',
	'~~': '\\approx',

	// More operators
	'≈': '\\approx',
	'?=': '\\questeq',
	'÷': '\\div',
	'¬': '\\neg',
	':=': '\\coloneq',
	'::': '\\Colon',

	// Big operators
	'∑': { mode: 'math', value: '\\sum' },
	sum: { mode: 'math', value: '\\sum_{#?}^{#?}' },
	prod: { mode: 'math', value: '\\prod_{#?}^{#?}' },
	sqrt: { mode: 'math', value: '\\sqrt' },
	'∫': '\\int', // There's a alt-B command for this

	'e^': {
		mode: 'math',
		value: '\\exponentialE ^{#?}'
	},

	// Differentials
	// According to ISO31/XI (ISO 80000-2), differentials should be upright
	dx: '\\differentialD x',
	dy: '\\differentialD y',
	dt: '\\differentialD t',
	'∆': { mode: 'math', value: '\\differentialD' }, // @TODO: is \\diffD most common?
	'∂': { mode: 'math', value: '\\differentialD' }
}

export const INLINE_TRIG_FUNCTIONS = {
	// Functions
	sin: { mode: 'math', value: '\\sin' },
	cos: { mode: 'math', value: '\\cos' },
	tan: { mode: 'math', value: '\\tan' },
	csc: { mode: 'math', value: '\\csc' },
	sec: { mode: 'math', value: '\\sec' },
	cot: { mode: 'math', value: '\\cot' },
	arcsin: { mode: 'math', value: '\\arcsin' },
	arccos: { mode: 'math', value: '\\arccos' },
	arctan: { mode: 'math', value: '\\arctan' },
	sinh: { mode: 'math', value: '\\sinh' },
	cosh: { mode: 'math', value: '\\cosh' },
	tanh: { mode: 'math', value: '\\tanh' },
	arsinh: { mode: 'math', value: '\\operatorname{arsinh}' },
	arcosh: { mode: 'math', value: '\\operatorname{arcosh}' },
	artanh: { mode: 'math', value: '\\operatorname{artanh}' },
	arcsinh: { mode: 'math', value: '\\operatorname{arcsinh}' },
	arccosh: { mode: 'math', value: '\\operatorname{arccosh}' },
	arctanh: { mode: 'math', value: '\\operatorname{arctanh}' }
}

export const INLINE_FUNCTIONS = {
	log: { mode: 'math', value: '\\log' },
	ln: { mode: 'math', value: '\\ln' },
	exp: { mode: 'math', value: '\\exp' },
	lim: { mode: 'math', value: '\\lim_{#?}' },

	// MORE FUNCTIONS
	arg: '\\arg',
	liminf: '\\operatorname*{lim~inf}_{#?}',
	limsup: '\\operatorname*{lim~sup}_{#?}',
	argmin: '\\operatorname*{arg~min}_{#?}',
	argmax: '\\operatorname*{arg~max}_{#?}',
	det: '\\det',
	mod: {
		mode: 'math',
		value: '\\mod'
	},
	max: {
		mode: 'math',
		value: '\\max'
	},
	min: {
		mode: 'math',
		value: '\\min'
	},
	erf: '\\operatorname{erf}',
	erfc: '\\operatorname{erfc}',
	bessel: {
		mode: 'math',
		value: '\\operatorname{bessel}'
	},
	mean: {
		mode: 'math',
		value: '\\operatorname{mean}'
	},
	median: {
		mode: 'math',
		value: '\\operatorname{median}'
	},
	fft: {
		mode: 'math',
		value: '\\operatorname{fft}'
	},
	lcm: {
		mode: 'math',
		value: '\\operatorname{lcm}'
	},
	gcd: {
		mode: 'math',
		value: '\\operatorname{gcd}'
	},
	floor: {
		mode: 'math',
		value: '\\operatorname{floor}'
	},
	ceil: {
		mode: 'math',
		value: '\\operatorname{ceil}'
	},
	randomReal: '\\operatorname{randomReal}',
	randomInteger: '\\operatorname{randomInteger}'
}

export const INLINE_COMPLEX_FUNCTIONS = {
	Re: {
		mode: 'math',
		value: '\\operatorname{Re}',
		example: '\\operatorname{Re}\\mleft(1+2\\imaginaryI\\mright)=1'
	},
	Im: {
		mode: 'math',
		value: '\\operatorname{Im}',
		example: '\\operatorname{Im}\\mleft(1+2\\imaginaryI\\mright)=2'
	},
	Arg: {
		mode: 'math',
		value: '\\operatorname{Arg}',
		example: '\\operatorname{Arg}\\mleft(1\\angle 2\\mright)=2'
	},
	Abs: {
		mode: 'math',
		value: '\\operatorname{Abs}',
		example: '\\operatorname{Abs}\\mleft(1\\angle 2\\mright)=1'
	},
	conj: {
		mode: 'math',
		value: '\\operatorname{conj}',
		example: '\\operatorname{conj}\\mleft(1+2\\imaginaryI\\mright)=1-2\\imaginaryI'
	}
}

export const INLINE_LOGIC = {
	// // Logic
	AA: { mode: 'math', value: '\\forall' },
	EE: { mode: 'math', value: '\\exists' },
	'!EE': { mode: 'math', value: '\\nexists' },
	'&&': { mode: 'math', value: '\\land' },
	// The shortcut for the greek letter "xi" is interfering with "x in"
	// xin: {
	// 	mode: 'math',
	// 	after: 'nothing+text+relop+punct+openfence+space',
	// 	value: 'x \\in'
	// },
	in: {
		mode: 'math',
		after: 'nothing+letter+closefence',
		value: '\\in'
	},
	'!in': { mode: 'math', value: '\\notin' },
	// More Logic
	forall: '\\forall',
	exists: {
		mode: 'math',
		value: '\\exists'
	},
	'!exists': {
		mode: 'math',
		value: '\\nexists'
	},
	':.': {
		mode: 'math',
		value: '\\therefore'
	}
}

export const INLINE_SETS = {
	// // Sets
	NN: '\\N', // Natural numbers
	ZZ: '\\Z', // Integers
	QQ: '\\Q', // Rational numbers
	RR: '\\R', // Real numbers
	CC: '\\C', // Complex numbers
	PP: '\\P' // Prime numbers
}

// Not sure how to use these
export const INLINE_ASCIII = {
	//
	// ASCIIIMath
	//
	// Binary operation symbols
	'**': '\\ast',
	'***': '\\star',
	'//': '\\slash',
	'\\\\': '\\backslash',
	setminus: '\\backslash',
	'|><': '\\ltimes',
	'><|': '\\rtimes',
	'|><|': '\\bowtie',
	'-:': '\\div',
	divide: '\\div',
	'@': '\\circ',
	'o+': '\\oplus',
	ox: '\\otimes',
	'o.': '\\odot',
	'^^': '\\wedge',
	'^^^': '\\bigwedge',
	vv: '\\vee',
	vvv: '\\bigvee',
	nn: '\\cap',
	nnn: '\\bigcap',
	uu: '\\cup',
	uuu: '\\bigcup',

	// Binary relation symbols
	'-=': '\\equiv',
	'~=': '\\cong',
	lt: '<',
	'lt=': '\\leq',
	gt: '>',
	'gt=': '\\geq',
	'-<': '\\prec',
	'-lt': '\\prec',
	'-<=': '\\preceq',
	// '>-':                   '\\succ',
	'>-=': '\\succeq',
	prop: '\\propto',
	diamond: '\\diamond',
	square: '\\square',
	iff: '\\iff',

	sub: '\\subset',
	sup: '\\supset',
	sube: '\\subseteq',
	supe: '\\supseteq',
	uarr: '\\uparrow',
	darr: '\\downarrow',
	rarr: '\\rightarrow',
	rArr: '\\Rightarrow',
	larr: '\\leftarrow',
	lArr: '\\Leftarrow',
	harr: '\\leftrightarrow',
	hArr: '\\Leftrightarrow',
	aleph: '\\aleph',

	// Logic
	and: '\\land',
	or: '\\lor',
	not: '\\neg',
	'_|_': '\\bot',
	TT: '\\top',
	'|--': '\\vdash',
	'|==': '\\models',

	// Arrows
	'>->': '\\rightarrowtail',
	'->>': '\\twoheadrightarrow',
	'>->>': '\\twoheadrightarrowtail'
}

export const ADDITIONAL_INLINE_COMPLEX_NUMBER_SHORTCUTS = {
	i: '\\imaginaryI',
	j: '\\imaginaryJ'
}

export const INLINE_SHORTCUTS = {
	...INLINE_OPERATORS,
	...INLINE_TRIG_FUNCTIONS,
	...INLINE_FUNCTIONS,
	...INLINE_COMPLEX_FUNCTIONS,
	...INLINE_GREEK,
	...INLINE_SYMBOLS
}

export const INLINE_VARIABLE_EXAMPLES = {
	'{{A}}': '\\variable{A}',
	'{{Angle_1}}': '\\variable{Angle_1}',
	'{{\\theta_1}}': '\\variable{\\theta _1}',
	'{{t_{var}}}': '\\variable{t_{var}}',
	'{{t_{i,j}}}': '\\variable{t_{i,j}}'
}

export const KEYSTROKE_SPEAKING = {
	parent: {
		windows: ['control + alt + ↑', 'control + alt + shift + ↑'],
		mac: ['control + ⌘ + ↑']
	},
	all: { windows: ['control + alt + ↓', 'control + alt + shift + ↓'], mac: ['control + ⌘ + ↓'] },
	left: { windows: ['control + alt + ←', 'control + alt + shift + ←'], mac: ['control + ⌘ + ←'] },
	right: { windows: ['control + alt + →', 'control + alt + shift + →'], mac: ['control + ⌘ + →'] }
}

export const KEYSTROKE_NAVIGATION = {
	'move up': ['↑'],
	'move down': ['↓'],
	'move before parent': ['shift + space'],
	'move after parent': ['space'],
	'move to previous placeholder': ['shift + tab', 'shift + F8'],
	'move to next placeholder': ['tab', 'F8'],
	'move to opposite': ['control + 5'],
	'move to superscript': ['control + 6'],
	'move to subscript': ['control + -'],
	'move to previous char': ['←'],
	'move to next char': ['→'],
	'move to previous word': ['alt + ←'],
	'move to next word': ['alt + →'],
	'move to group start': ['control + ←'],
	'move to group end': ['control + →'],
	'move to math field start': ['home', '⌘ + ←'],
	'move to math field end': ['end', '⌘ + →'],
	'delete previous char': ['backspace', 'alt + del'],
	'delete next char': ['del', 'alt + backspace'],
	'extend selection up': ['shift + ↑'],
	'extend selection down': ['shift + ↓'],
	'extend selection to previous char': ['shift + ←'],
	'extend selection to next char': ['shift + →'],
	'extend selection to previous word': ['alt + shift + ←'],
	'extend selection to next word': ['alt + shift + →'],
	'extend selection to group start': ['control + shift + ←'],
	'extend selection to group end': ['control + shift + →'],
	'extend selection to math field start': ['shift + home', '⌘ + shift + ←'],
	'extend selection to math field end': ['shift + end', '⌘ + shift + →']
}
