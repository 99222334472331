import { Component } from 'react'
import { connect } from 'react-redux'
import { KeyboardsState } from '../redux/reducers/keyboardsReducer'
import { ReduxState } from '../types/ReduxState'

export interface KeyboardHandlerReduxProps {
	keyboards: KeyboardsState
}

export class KeyboardHandler extends Component<KeyboardHandlerReduxProps> {
	componentDidUpdate(prevProps: KeyboardHandlerReduxProps) {
		const { keyboards } = this.props
		// some keyboards are open
		if (Object.keys(keyboards).length > 0) {
			document.body.classList.add('keyboard-open')
		}
		// no keyboards are open
		else {
			document.body.classList.remove('keyboard-open')
		}
	}

	render() {
		return <></>
	}
}

const mapStateToProps = (state: ReduxState): KeyboardHandlerReduxProps => {
	return {
		keyboards: state.keyboards
	}
}

const WrappedKeyboardHandler = connect(mapStateToProps)(KeyboardHandler)
export default WrappedKeyboardHandler
