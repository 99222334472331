import { Action } from 'redux'

export enum KEYBOARD_ACTION_TYPE {
	KEYBOARD_OPEN = 'keyboard/OPEN',
	KEYBOARD_CLOSE = 'keyboard/CLOSE'
}

export interface KeyboardAction extends Action<KEYBOARD_ACTION_TYPE> {
	guid: string
}
