import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { FunctionComponent } from 'react'

export const IconAssessmentCreator: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			d="M10,4c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S7.8,4,10,4 M10,14c0.4,0,0.9,0,1.3,0v6H2v-2C2,15.8,5.6,14,10,14 M18.3,15.7h3  
			l-3-3V15.7 M14.7,11.8h4.2l3.2,3.2v6.3c0,0.6-0.5,1.1-1.1,1.1h-6.3c-0.6,0-1.1-0.5-1.1-1.1v-8.4C13.7,12.3,14.1,11.8,14.7,11.8  
			M10,4c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S7.8,4,10,4"
		/>
	</SvgIcon>
)
