import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { FunctionComponent } from 'react'

export const IconSheriff: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			d="M20 22H4V20C4 17.8 7.6 16 12 16S20 17.8 20 20M8 9H16V10C16 12.2 14.2 14 12 14S8 12.2 8 10M19 4C18.4 4 18 4.4 18 
		    5V6H16.5L15.1 3C15 2.8 14.9 2.6 14.7 2.5C14.2 2 13.4 1.9 12.7 2.2L12 2.4L11.3 2.1C10.6 1.8 9.8 1.9 9.3 2.4C9.1 2.6 9 2.8 8.9 
		    3L7.5 6H6V5C6 4.4 5.6 4 5 4S4 4.4 4 5V6C4 7.1 4.9 8 6 8H18C19.1 8 20 7.1 20 6V5C20 4.5 19.6 4 19 4Z"
		/>
	</SvgIcon>
)
