import { Dictionary } from 'lodash'
import { Action } from 'redux'
import { KEYBOARD_ACTION_TYPE, KeyboardAction } from '../actions/KeyboardAction'

export interface KeyboardsAction extends Action {
	guid: string
}

export interface KeyboardsState extends Dictionary<boolean> {}

/// state is a dictionary where keys are keyboard guids, for easy lookup
const initialState: KeyboardsState = {}

export default function keyboardsReducer(state = initialState, action: KeyboardAction) {
	let guid: string
	switch (action.type) {
		case KEYBOARD_ACTION_TYPE.KEYBOARD_OPEN: {
			guid = action.guid
			if (state[guid]) {
				return state
			}
			const newState = { ...state, ...{ [`${guid}`]: true } }
			return newState
		}
		case KEYBOARD_ACTION_TYPE.KEYBOARD_CLOSE: {
			guid = action.guid
			if (!state[guid]) {
				return state
			}
			// destructure `state`, separating the value of key `guid` to `removed`, and the rest of the keys to `stateCopy`
			const { [guid]: removed, ...stateCopy } = state
			return stateCopy
		}
		default:
			return state
	}
}
