export enum NUMBER_FORMAT_TYPE {
	NUMBER = 'Number',
	NUMBER_WITH_SEPARATORS = 'NumberWithSeparators',
	PERCENTAGE = 'Percentage',
	FRACTION = 'Fraction',
	SCI_NOTATION = 'SciNotation',
	E_NOTATION = 'ENotation',
	US_DOLLAR = 'USDollar',
	/* complex number is only used in the number format dropdown  */
	COMPLEX_NUMBER = 'ComplexNumber',
	COMPLEX_RECTANGULAR_I = 'ComplexRectangularI',
	COMPLEX_RECTANGULAR_J = 'ComplexRectangularJ',
	COMPLEX_POLAR_RADIAN = 'ComplexPolarRadian',
	COMPLEX_POLAR_DEGREE = 'ComplexPolarDegree'
}

// COMPLEX_NUMBER is only used for display, but is never set for the current type
export const COMPLEX_NUMBER_FORMAT_TYPES = [
	NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I,
	NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J,
	NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN,
	NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE
]
