import { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { getAppConfig, TIER } from 'studiokit-scaffolding-js'
import logo from './logo.svg'

import './index.css'

export interface LogoProps {
	useLink?: boolean
}

export const Logo: FunctionComponent<LogoProps> = ({ useLink = true }) => {
	const appConfig = getAppConfig()
	const tier = appConfig.TIER

	const tierOverlay = useMemo(() => {
		switch (tier) {
			case TIER.LOCAL:
				return <span>LOCAL</span>
			case TIER.DEV:
				return <span>DEV</span>
			case TIER.QA:
				return <span>QA</span>
			default:
				return null
		}
	}, [tier])

	return useLink ? (
		<Link to="/" aria-label="Link to home page" className="navbar-logo">
			<h1 className="visually-hidden">Variate</h1>
			<img src={logo} alt="" />
			{tierOverlay}
		</Link>
	) : (
		<div className="navbar-logo">
			<h1 className="mv0">
				<img src={logo} alt="Variate" />
			</h1>
			{tierOverlay}
		</div>
	)
}
