import { Dictionary } from 'lodash'
import MathLive from 'mathlive'
import ParchmentType from 'parchment'
import { Blot } from 'parchment/dist/src/blot/abstract/blot'
import { Quill } from 'react-quill'
import { getFirstParentElementWithClassName } from 'studiokit-scaffolding-js/lib/utils/dom'
import { getMathlistFontScaleFactor } from '../../../../../utils/mathlive'

const Parchment = Quill.import('parchment') as typeof ParchmentType
const Embed = Quill.import('blots/embed') as typeof ParchmentType.Embed

type VariableBlotOnClick = (variableName: string, blot: Blot) => void
type VariableBlotOnCreate = (variableName: string, mathlist: any) => void

let clickHandlersEnabled = true
const onClickHandlers: Dictionary<VariableBlotOnClick | undefined> = {}
const onCreateHandlers: Dictionary<VariableBlotOnCreate | undefined> = {}

export function setVariableBlotClickHandlersEnabled(value: boolean) {
	clickHandlersEnabled = value
}

export function setOnVariableBlotClick(guid: string, value: VariableBlotOnClick | undefined) {
	if (value) {
		onClickHandlers[guid] = value
	} else {
		delete onClickHandlers[guid]
	}
}

export function setOnVariableBlotCreate(guid: string, value: VariableBlotOnCreate | undefined) {
	if (value) {
		onCreateHandlers[guid] = value
	} else {
		delete onCreateHandlers[guid]
	}
}

export class VariableBlot extends Embed {
	static blotName = 'variable'
	static tagName = 'SPAN'
	static className = 'variable'

	static create(value: any) {
		const node = super.create(value) as HTMLSpanElement
		if (typeof value === 'string') {
			node.setAttribute('data-value', value)
			node.addEventListener('click', (ev: Event) => {
				const blot = Parchment.find(node)
				const quillEdit = getFirstParentElementWithClassName(node, 'QuillEdit')
				if (!quillEdit) {
					return
				}
				const onClick = onClickHandlers[quillEdit.id]
				if (onClick && clickHandlersEnabled && blot) {
					onClick(value, blot)
				}
			})

			// Render MathLive inline
			const child = document.createElement('script')
			child.textContent = `\\variable{${value}}`
			child.setAttribute('data-value', value)
			child.setAttribute('type', 'math/tex')
			node.append(child)
			MathLive.renderMathInElement(node, {
				renderAccessibleContent: 'speakable-text',
				onCreateMathlist: (mathlist: any) => {
					// wrap mathlist (array of MathAtoms) in a "root" atom
					// allows use of mathlive's custom `forEach` method
					const rootAtom = MathLive.MathAtom.makeRoot('math', mathlist)

					// since the node is not attached to the DOM yet
					// we don't know which handler to call
					// so call them all and let each one decide if this
					// create is applicable
					for (const key in onCreateHandlers) {
						const onCreate = onCreateHandlers[key]
						if (onCreate) {
							onCreate(value, rootAtom)
						}
					}

					// set dynamic font size
					const fontSizeScaleFactor = getMathlistFontScaleFactor(rootAtom)
					node.classList.add(`math-scale-${fontSizeScaleFactor}`)
				}
			})

			// Add LaTeX to DOM for copy-paste
			const latexChild = document.createElement('span')
			latexChild.textContent = `\\variable{${value}}`
			latexChild.setAttribute('class', 'visually-hidden')
			latexChild.setAttribute('aria-hidden', 'true')
			node.append(latexChild)
		}
		return node
	}

	static value(domNode: HTMLSpanElement) {
		return domNode.getAttribute('data-value')
	}
}
