import { stripSpaceCommands } from './numberFormat'

//#region Constants

export const WORDS = {
	COPY_TO_CLIPBOARD: 'Copy to clipboard',
	COPIED: 'Copied'
}

export const RESET_MILLISECONDS = 2000

const assignmentOutlinedPath =
	'M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7zm12-4h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z'

const assignmentTurnedInOutlinedPath =
	'M18 9l-1.41-1.42L10 14.17l-2.59-2.58L6 13l4 4zm1-6h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z'

//#endregion

export const copyToClipboard = (text: string) => {
	const elem = document.createElement('textarea')
	document.body.appendChild(elem)
	elem.value = stripSpaceCommands(text)
	elem.select()
	document.execCommand('copy')
	document.body.removeChild(elem)
}

/**
 * A vanilla-JS implementation of `CopyToClipboardButton`, to be used inside of `FormulaBlot`,
 * which uses direct DOM modifications.
 *
 * @param parent The parent node that will contain the button
 * @param text The text that should be copied to the clipboard on click
 */
export const appendCopyToClipboardDOMNode = (parent: HTMLElement, text: string) => {
	// determine parent size by temporarily appending to body
	const originalVisibility = parent.style.visibility
	const originalPosition = parent.style.position
	parent.style.visibility = 'hidden'
	parent.style.position = 'absolute'
	document.body.appendChild(parent)
	const parentSize = {
		width: parent.clientWidth,
		height: parent.clientHeight
	}
	parent.style.visibility = originalVisibility
	parent.style.position = originalPosition
	parent.parentNode?.removeChild(parent)

	const button = document.createElement('button')
	button.className = 'btn-copy-to-clipboard'
	button.tabIndex = 0
	button.type = 'button'
	if (parentSize.width < 60) {
		button.style.right = '-29px'
	}
	parent.append(button)

	const label = document.createElement('span')
	label.className = 'MuiIconButton-label'
	button.append(label)

	const xmlns = 'http://www.w3.org/2000/svg'
	const svg = document.createElementNS(xmlns, 'svg', {})
	svg.setAttributeNS(null, 'class', 'MuiSvgIcon-root')
	svg.setAttributeNS(null, 'focusable', 'false')
	svg.setAttributeNS(null, 'viewBox', '0 0 24 24')
	svg.setAttributeNS(null, 'aria-hidden', 'true')
	label.append(svg)

	const path = document.createElementNS(xmlns, 'path')
	svg.append(path)

	const reset = () => {
		path.setAttributeNS(null, 'd', assignmentOutlinedPath)
		button.title = WORDS.COPY_TO_CLIPBOARD
		button.setAttribute('aria-label', WORDS.COPY_TO_CLIPBOARD)
	}

	button.onclick = () => {
		path.setAttributeNS(null, 'd', assignmentTurnedInOutlinedPath)
		button.title = WORDS.COPIED
		button.setAttribute('aria-label', WORDS.COPIED)
		copyToClipboard(text)
		window.setTimeout(() => {
			reset()
		}, RESET_MILLISECONDS)
	}

	reset()
}
