export enum ACTIVITY {
	ASSESSMENT_READ = 'AssessmentRead',
	ASSESSMENT_CREATE = 'AssessmentCreate',
	ASSESSMENT_UPDATE = 'AssessmentUpdate',
	ASSESSMENT_DELETE = 'AssessmentDelete',
	ASSESSMENT_USER_ROLE_READ = 'AssessmentUserRoleRead',
	ASSESSMENT_USER_ROLE_MODIFY = 'AssessmentUserRoleModify',
	ASSESSMENT_USER_ROLE_DELETE_OWN = 'AssessmentUserRoleDeleteOwn',

	PROBLEM_READ = 'ProblemRead',
	PROBLEM_CREATE = 'ProblemCreate',
	PROBLEM_UPDATE = 'ProblemUpdate',
	PROBLEM_DELETE = 'ProblemDelete',
	PROBLEM_USER_ROLE_READ = 'ProblemUserRoleRead',
	PROBLEM_USER_ROLE_MODIFY = 'ProblemUserRoleModify',
	PROBLEM_USER_ROLE_DELETE_OWN = 'ProblemUserRoleDeleteOwn',
	PROBLEM_CREATE_WITH_PROMPT = 'ProblemCreateWithPrompt',

	SHARED_LIBRARY_READ = 'SharedLibraryRead',
	SHARED_LIBRARY_CREATE = 'SharedLibraryCreate',
	SHARED_LIBRARY_UPDATE = 'SharedLibraryUpdate',
	SHARED_LIBRARY_DELETE = 'SharedLibraryDelete',
	SHARED_LIBRARY_USER_ROLE_READ = 'SharedLibraryUserRoleRead',
	SHARED_LIBRARY_USER_ROLE_MODIFY = 'SharedLibraryUserRoleModify',
	SHARED_LIBRARY_USER_ROLE_DELETE_OWN = 'SharedLibraryUserRoleDeleteOwn',

	PROBLEM_SHARED_LIBRARY_ROLE_READ = 'ProblemSharedLibraryRoleRead',
	PROBLEM_SHARED_LIBRARY_ROLE_CREATE = 'ProblemSharedLibraryRoleCreate',
	PROBLEM_SHARED_LIBRARY_ROLE_UPDATE = 'ProblemSharedLibraryRoleUpdate',
	PROBLEM_SHARED_LIBRARY_ROLE_DELETE = 'ProblemSharedLibraryRoleDelete',

	GROUP_ASSESSMENT_READ_ANY = 'GroupAssessmentReadAny',
	GROUP_ASSESSMENT_CACHE_PURGE_ANY = 'GroupAssessmentCachePurgeAny',
	GROUP_ASSESSMENT_CREATE = 'GroupAssessmentCreate',
	GROUP_ASSESSMENT_UPDATE = 'GroupAssessmentUpdate',
	GROUP_ASSESSMENT_DELETE = 'GroupAssessmentDelete',
	GROUP_ASSESSMENT_READ_PUBLISHED = 'GroupAssessmentReadPublished',
	GROUP_ASSESSMENT_SUBMIT = 'GroupAssessmentSubmit',
	GROUP_ASSESSMENT_RESULT_READ_ANY = 'GroupAssessmentResultReadAny',
	GROUP_ASSESSMENT_RESULT_READ_OWN = 'GroupAssessmentResultReadOwn',
	GROUP_ASSESSMENT_PROBLEM_INSTANCE_ATTEMPT_CREATE = 'GroupAssessmentProblemInstanceAttemptCreate',
	GROUP_ASSESSMENT_PROBLEM_INSTANCE_ATTEMPT_UPDATE = 'GroupAssessmentProblemInstanceAttemptUpdate',

	LEARNER_STATUS_EVENT_CREATE = 'LearnerStatusEventCreate'
}

export default ACTIVITY
