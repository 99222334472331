import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { FunctionComponent } from 'react'

export const IconProblemCreator: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			d="M10,4c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S7.8,4,10,4 M10,14c0.2,0,0.4,0,0.6,0v6H2v-2C2,15.8,5.6,14,10,14 
			M21.4,12.3  h-7.7c-0.6,0-1.1,0.5-1.1,1.1v7.7c0,0.6,0.5,1.1,1.1,1.1h7.7c0.6,0,1.1-0.5,1.1-1.1v-7.7C22.5,12.8,22,12.3,21.4,12.3 
			M18.1,14.5  l0.6-0.6l0.8,0.8l0.8-0.8l0.6,0.6l-0.8,0.8l0.8,0.8l-0.6,0.6l-0.8-0.8l-0.8,0.8l-0.6-0.6l0.8-0.8L18.1,14.5 
			M14.4,14.9h2.7v0.8h-2.7  V14.9 M17.3,19.4h-1.1v1.1h-0.8v-1.1h-1.1v-0.8h1.1v-1.1h0.8v1.1h1.1V19.4 M20.9,20.1h-2.7v-0.8h2.7V20.1 
			M20.9,18.8h-2.7V18h2.7  V18.8z"
		/>
	</SvgIcon>
)
