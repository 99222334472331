import React from 'react'
import { ModelCollection } from 'studiokit-scaffolding-js/lib/types'
import { SharedLibrary } from '../../../types/SharedLibrary'
import { SearchResult } from './index'

export interface SearchResultsContextType {
	keywords: string
	handleKeywordsChange: (event: any) => void
	handleKeywordsKeyDown: (event: any) => void
	resetSearch: () => void
	loadProblems: () => void
	isLoading: boolean
	search: () => void
	triggerRerender: () => void
	searchResults?: SearchResult[]
	problemIdsAlreadyShared: Set<number> | undefined
	addAlreadySharedProblemIds: (sharedProblemIds: number[]) => void
	removeAlreadySharedProblemId: (unsharedProblemId: number) => void
	problemIdsNotShareable: Set<number> | undefined
	setUnshareableProblemIds: (unshareableProblemIds: number[]) => void
	filteredSharedLibraryIds: Set<number>
	sharedLibraries: ModelCollection<SharedLibrary>
	setSharedLibraryFilter: (sharedLibraryId: number, isSelected: boolean) => void
	isFilteringOwnProblems: boolean
	setIsFilteringOwnProblems: (isSelected: boolean) => void
	isOpen: boolean
	toggleIsOpen: () => void
	toggleSearchResultSelected: (event: any) => void
	selectAllSearchResults: () => void
	unselectSearchResults: () => void
	doTagSearch: (tag: string) => void
	hidePartialCreditIndicators?: boolean
}

/*
 * Context provided to the children of AssessmentCreateOrEdit for SearchResults.  Default values should be initialized
 * by the context provider.
 */
export const SearchResultsContext = React.createContext<SearchResultsContextType>(
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	undefined!
)
