import IconAssignmentInd from '@material-ui/icons/AssignmentInd'
import IconGroup from '@material-ui/icons/Group'
import IconFile from '@material-ui/icons/InsertDriveFile'
import IconSchool from '@material-ui/icons/School'
import React, { FunctionComponent } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { BASE_ACTIVITY } from 'studiokit-scaffolding-js'
import GroupsDropdown from 'studiokit-scaffolding-js/lib/components/Dropdowns/GroupsDropdown'
import { ManagedNavDropdown } from 'studiokit-scaffolding-js/lib/components/Dropdowns/ManagedNavDropdown'
import { UserDropdown } from 'studiokit-scaffolding-js/lib/components/Dropdowns/UserDropdown'
import { UserInfo } from 'studiokit-scaffolding-js/lib/types'
import {
	ActivityOptions,
	canPerformActivityGlobally,
	canPerformActivityGloballyOrOnSomeEntities
} from 'studiokit-scaffolding-js/lib/utils/baseActivity'
import { groupsAsAnythingButLearner } from 'studiokit-scaffolding-js/lib/utils/groupRoles'
import ACTIVITY from '../../constants/activity'
import { ReduxState } from '../../types/ReduxState'
import { IconAIProblemCreator } from '../Shared/Icons/IconAIProblemCreator'
import { IconAssessmentCreator } from '../Shared/Icons/IconAssessmentCreator'
import { IconProblem } from '../Shared/Icons/IconProblem'
import { IconProblemCreator } from '../Shared/Icons/IconProblemCreator'
import { IconPurgeCache } from '../Shared/Icons/IconPurgeCache'
import { IconSharedLibrary } from '../Shared/Icons/IconSharedLibrary'
import { IconSheriff } from '../Shared/Icons/IconSheriff'
import { Logo } from './Logo'

import './index.css'

export interface HeaderOwnProps {
	isDecorative?: boolean
}

export interface HeaderReduxProps {
	isAuthenticated: boolean
	currentUser?: UserInfo
	canCreateGroups: boolean
	canReadGroupAsAnythingButLearner: boolean
	canReadAnyUserRole: boolean
	canReadAnyUser: boolean
	canCreateAssessments: boolean
	canReadAssessment: boolean
	canCreateProblems: boolean
	canReadProblem: boolean
	canCreateSharedLibraries: boolean
	canReadSharedLibrary: boolean
	canPurgeAnyGroupAssessmentCache: boolean
}

export const Header: FunctionComponent<HeaderOwnProps & HeaderReduxProps & RouteComponentProps> = ({
	isAuthenticated,
	isDecorative,
	currentUser,
	canCreateGroups,
	canReadGroupAsAnythingButLearner,
	canReadAnyUserRole,
	canReadAnyUser,
	canCreateAssessments,
	canReadAssessment,
	canCreateProblems,
	canReadProblem,
	canCreateSharedLibraries,
	canReadSharedLibrary,
	canPurgeAnyGroupAssessmentCache
}) => {
	let authenticatedMenu = null

	if (isAuthenticated && !isDecorative && !!currentUser && !!currentUser.id) {
		const showManageMenu =
			// courses
			canReadGroupAsAnythingButLearner ||
			canCreateGroups ||
			// assessments
			canCreateAssessments ||
			canReadAssessment ||
			// problems
			canCreateProblems ||
			canReadProblem ||
			// shared libraries
			canCreateSharedLibraries ||
			canReadSharedLibrary
		const showAdminMenu =
			// users, problem creators, admins
			canReadAnyUser ||
			canReadAnyUserRole ||
			// cache
			canPurgeAnyGroupAssessmentCache
		authenticatedMenu = (
			<Navbar.Collapse id="navbar-collapsable">
				<Nav role="navigation" className="ml-auto">
					<LinkContainer to="/" exact>
						<Nav.Link>Home</Nav.Link>
					</LinkContainer>
					<GroupsDropdown title="Courses You’re Teaching" id="groupsTeaching" anyRoleButLearner />
					<GroupsDropdown title="Courses You’re Taking" id="groupsTaking" />
					{showManageMenu && (
						<ManagedNavDropdown id="manage-menu" title="Manage" renderMenuOnMount>
							{(canReadGroupAsAnythingButLearner || canCreateGroups) && (
								<LinkContainer to="/courses" exact>
									<NavDropdown.Item id="manageCoursesNavDropdownItem">
										<IconSchool className="mr2 icon-size-2 pb1" />
										Courses
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{(canCreateAssessments || canReadAssessment) && (
								<LinkContainer to="/assessments" exact>
									<NavDropdown.Item id="manageAssessmentsNavDropdownItem">
										<IconFile className="mr2 icon-size-2 pb1" />
										Assessments
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{(canCreateProblems || canReadProblem) && (
								<LinkContainer to="/problems" exact>
									<NavDropdown.Item id="manageProblemsNavDropdownItem">
										<IconProblem className="mr2 icon-size-2 pb1" />
										Problems
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{(canCreateSharedLibraries || canReadSharedLibrary) && (
								<LinkContainer to="/sharedLibraries" exact>
									<NavDropdown.Item id="manageSharedLibrariesNavDropdownItem">
										<IconSharedLibrary className="mr2 icon-size-2 pb1" />
										Shared Libraries
									</NavDropdown.Item>
								</LinkContainer>
							)}
						</ManagedNavDropdown>
					)}
					{showAdminMenu && (
						<ManagedNavDropdown id="admin-menu" title="Admin" renderMenuOnMount>
							{canReadAnyUser && (
								<LinkContainer to="/users" exact>
									<NavDropdown.Item id="manageUsersNavDropdownItem">
										<IconGroup className="mr2 icon-size-2 pb1" />
										Users
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{canReadAnyUser && canReadAnyUserRole && <NavDropdown.Divider />}
							{canReadAnyUserRole && (
								<>
									<LinkContainer to="/creators" exact>
										<NavDropdown.Item id="manageCreatorsNavDropdownItem">
											<IconSheriff className="mr2 icon-size-2 pb1" />
											Creators
										</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/assessment-creators" exact>
										<NavDropdown.Item id="manageAssessmentCreatorsNavDropdownItem">
											<IconAssessmentCreator className="mr2 icon-size-2 pb1" />
											Assessment Creators
										</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/problem-creators" exact>
										<NavDropdown.Item id="manageProblemCreatorsNavDropdownItem">
											<IconProblemCreator className="mr2 icon-size-2 pb1" />
											Problem Creators
										</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/ai-problem-creators" exact>
										<NavDropdown.Item id="manageAIProblemCreatorsNavDropdownItem">
											<IconAIProblemCreator className="mr2 icon-size-2 pb1" />
											AI Problem Creators
										</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admins" exact>
										<NavDropdown.Item id="manageAdminsNavDropdownItem">
											<IconAssignmentInd className="mr2 icon-size-2 pb1" />
											Admins
										</NavDropdown.Item>
									</LinkContainer>
								</>
							)}

							{/**
							 * currently only GAs are cached
							 * if other entities are cached, consider changing to a generic activity,
							 * or add other entity specific activities here.
							 */}
							{canPurgeAnyGroupAssessmentCache && (
								<>
									<NavDropdown.Divider />
									<LinkContainer to="/cache" exact>
										<NavDropdown.Item id="manageCacheNavDropdownItem">
											<IconPurgeCache className="mr2 icon-size-2 pb1" />
											Cache
										</NavDropdown.Item>
									</LinkContainer>
								</>
							)}
						</ManagedNavDropdown>
					)}
					<ManagedNavDropdown id="help-menu" title="Help" renderMenuOnMount>
						<LinkContainer to="/help" exact>
							<NavDropdown.Item id="helpDropdownItem">Using Variate</NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to="/help/lockdown-browser" exact>
							<NavDropdown.Item id="lockDownBrowserDropdownItem">LockDown Browser</NavDropdown.Item>
						</LinkContainer>
					</ManagedNavDropdown>
					<UserDropdown
						userInfo={currentUser}
						impersonationIconWrapperClassName="bg-color-purple color-white"
					/>
				</Nav>
			</Navbar.Collapse>
		)
	}

	return (
		<div className="navbar-container">
			<Navbar expand="md" collapseOnSelect bg="light" className="navbar-overall">
				<Navbar.Brand>
					<Logo useLink={!isDecorative} />
				</Navbar.Brand>
				{!!authenticatedMenu && <Navbar.Toggle aria-controls="navbar-collapsable" />}
				{authenticatedMenu}
			</Navbar>
		</div>
	)
}

const mapStateToProps = (state: ReduxState): HeaderReduxProps => {
	const isAuthenticated = state.auth && state.auth.isAuthenticated
	const userInfo = state.models.user ? state.models.user.userInfo : undefined
	return {
		isAuthenticated,
		currentUser: isAuthenticated ? userInfo : undefined,
		canReadAnyUserRole: canPerformActivityGlobally(BASE_ACTIVITY.USER_ROLE_READ_ANY, {
			userInfo
		} as ActivityOptions),
		canReadAnyUser: canPerformActivityGlobally(BASE_ACTIVITY.USER_READ_ANY, { userInfo }),
		canCreateGroups: canPerformActivityGlobally(BASE_ACTIVITY.GROUP_CREATE, { userInfo }),
		canReadGroupAsAnythingButLearner:
			canPerformActivityGlobally(BASE_ACTIVITY.GROUP_READ, { userInfo }) ||
			(!!state.models.groups && groupsAsAnythingButLearner(state.models.groups).length > 0),
		canCreateAssessments: canPerformActivityGlobally(ACTIVITY.ASSESSMENT_CREATE, { userInfo }),
		canReadAssessment:
			!!state.models.assessments &&
			canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.ASSESSMENT_READ, {
				userInfo,
				entities: state.models.assessments
			}),
		canCreateProblems: canPerformActivityGlobally(ACTIVITY.PROBLEM_CREATE, { userInfo }),
		canReadProblem:
			!!state.models.problems &&
			canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.PROBLEM_READ, {
				userInfo,
				entities: state.models.problems
			}),
		canCreateSharedLibraries: canPerformActivityGlobally(ACTIVITY.SHARED_LIBRARY_CREATE, { userInfo }),
		canReadSharedLibrary:
			!!state.models.sharedLibraries &&
			canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.SHARED_LIBRARY_READ, {
				userInfo,
				entities: state.models.sharedLibraries
			}),
		canPurgeAnyGroupAssessmentCache: canPerformActivityGlobally(ACTIVITY.GROUP_ASSESSMENT_CACHE_PURGE_ANY, {
			userInfo
		})
	}
}

const WrappedHeader = withRouter(connect(mapStateToProps)(Header))
export default WrappedHeader
