export enum VALIDITY_LEVEL {
	/** The target cannot be saved. */
	INVALID_FOR_SAVE = 'InvalidForSave',
	/** The target can be saved, but cannot be used. Used with existing/saved targets to display an "error" message. */
	INVALID_FOR_USE_ERROR = 'InvalidForUseError',
	/** The target can be saved, but cannot be used. Used with new/unsaved targets to display a less intrusive "info" message. */
	INVALID_FOR_USE_INFO = 'InvalidForUseInfo',
	/** The target can be save and used. */
	VALID = 'Valid'
}

export const getMostSevereValidityLevel = (validities: VALIDITY_LEVEL[]): VALIDITY_LEVEL => {
	if (validities.includes(VALIDITY_LEVEL.INVALID_FOR_SAVE)) return VALIDITY_LEVEL.INVALID_FOR_SAVE
	if (validities.includes(VALIDITY_LEVEL.INVALID_FOR_USE_ERROR)) return VALIDITY_LEVEL.INVALID_FOR_USE_ERROR
	if (validities.includes(VALIDITY_LEVEL.INVALID_FOR_USE_INFO)) return VALIDITY_LEVEL.INVALID_FOR_USE_INFO
	return VALIDITY_LEVEL.VALID
}
